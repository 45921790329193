/* General */

/* body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */
h2{
    width: 65%;
    margin: 50px 0 0;
    text-align: center;
    font-family: 'Anton', sans-serif;
        font-size: 2.5rem;
    color: black;
}

h4{
    width: 65%;
    margin: 30px 0 ;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5 rem;
    text-transform: uppercase;
    color: black;
}

p{
    width: 65%;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: black;
    margin: 10px 0 40px;

}



/* .button-link{
    margin: 45px 0 0;
} */
/* General */

/* Hero Section */
.hero{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero h1{
    width: 55%;
    max-width: none;
    text-align: center;
    margin-top: -50px;
    z-index: 98;
    font-family: 'Anton', sans-serif;
        font-size: 5rem;
    color: white;
}
.hero .overlay{
    z-index: 97;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .1;
}
.hero img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero .carousel-container{
    width: 100%;
    position: absolute;
}

.hero .carousel-indicators{
    display: none;
}

.hero .carousel-controls{
    display: none;
}




/* Hero Section */

/* Services */
/* .service-cards{
    min-width: 425px;
} */

/* .service-cards::after {
    flex-basis: 200px; 
    flex-grow: 1;
    content: ""; 
  } */

/* .service-cards::after {
    display: none;
  } */
.service-card{
    position: relative;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
.service-card img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.service-card p{
    width: 55%;
    text-align: center;
    z-index: 98;
    font-family: 'Anton', sans-serif;
        font-size: 1.3rem;
    color: white;
    margin: 5px;
    text-transform: uppercase;
}
.service-card .overlay{
    z-index: 97;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .4;
}
/* Services */

/* About */
.about-section{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 6%;
}
.about-section img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
.about-section h1{
    width: 65%;
    margin: 20px 0;
    text-align: left;
    /* font-family: 'Anton', sans-serif; */
        font-size: 5rem;
    text-transform: uppercase;
    color: #D1B479;
}
.about-section p{
    text-align: left;
    margin: 15px 0 45px;
    width: 100%;
}
.about-section h4{
margin: 0;
}


.about .button-link{
    margin: 0;
    
}

.about-vision-rb{
    width: 85%;
    max-width: 1280px;
    background-color: white;
    padding: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.vision-content{
    /* min-width: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
    flex-basis: 50%;
    padding: 0 30px;
}
/* About */

/* Projects -> in main.css */


/* Clients */

.clients-section{
    background-color: #F8F8F8;
    padding: 100px 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    .clients-section h2{
    margin: 0 0 50px;
}

.slider {
	background: #F8F8F8;
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
	height: 100px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 85%;
}

.slider::before,
.slider::after {
	background: linear-gradient(to right, #F8F8F8 0%, rgba(255, 255, 255, 0) 100%);
	content: "";
	height: 100px;
	position: absolute;
	width: 100px;
	z-index: 2;
}

.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}

.slider::before {
	left: 0;
	top: 0;
}

.slide-track {
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(250px * 25);
}

.slide {
	height: 100px;
	width: 250px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
}

.slide img{
    object-fit:contain;

}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(calc(-250px * 24));
	}
}

/* News */
.news-section{
    /* display: none; */
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

}

.news-cards{
    width: 75%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;
    margin: 0 0 45px;
}

.news-card{
    position: relative;
    width: 300px;
    height: 100px;
}

.news-cards:nth-child(1){
width: 500px;}

.news-cards img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.news-card p{
    z-index: 99;
}



.certif{
background-color: #F8F8F8;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 100px 0;
}

.certif p{
width: 75%;
}
.certif h2{
    margin: 0;
}

.certif-cards{
    /* max-width: 1280px; */
    width: 75%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
}
.certif-cards::after {
    flex-basis: 525px; 
    content: ""; 
}

.certif-card{
    flex-grow: 1;
    position: relative;
    min-width: 170px;
    min-height: 220px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.certif-card:hover{
    cursor:pointer;
}

.certif-card::before{
    content: '';
    padding-top: 80%;
}

.certif-card img{
    position: absolute;
    width: 70%;
    height: 70%;
    object-fit: cover;
}
/* Media Queries */
/* hero */
@media(max-width:768px){
    .hero h1{
        width: 65%;
        font-size: 3.5rem;
    }

    .about-vision-rb{
        padding: 30px 10px;
       
    }

   

}


/* services */
@media screen and (max-width:1466px){
    .service-card:nth-child(9){
        display:none;
    }
    .service-card:nth-child(10){
        display:none;
    }
}
@media screen and (max-width:1166px){
    .service-card:nth-child(7){
        display:none;
    }
    .service-card:nth-child(8){
        display:none;
    }
}

@media(max-width:740px){
    .service-card{
        width: 170px;
    }  
}


@media(max-width:500px){
    .service-card{
        width: 140px;
    }  

    .certif-card{
        min-width: 130px;
    }
}


/* About */
@media(max-width:1050px){
    .about-section h1{
        width: 100%;
        font-size: 3.5rem;
    }

    .vision-content{
      
        /* flex-basis: 50%; */
        flex-basis: 100%;
       
    }
}

/* Certif */
@media(max-width:1006px){
    .certif-card{
        min-width: 250px;
    }
}

@media(max-width:740px){
    .certif-card{
        min-width: 170px;
    }

    .about-section h1{
        /* font-size: 5rem; */
        font-size: 2.5rem;
      }
}


/* all */





  @media(max-width:700px){
  

    .about-section h1{
        /* font-size: 5rem; */
        font-size: 2.5rem;
      }
}



/* Media Queries */


/* Animations */
    /* .anim-lineUp {
        animation: 2s anim-lineUp ease-in-out;
}

@keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  } */
/* Animations */



/* Animations */
.anim-lineUp {
    opacity: 0;
    transform: translateY(80%);
  }
  
  .animate {
    animation: anim-lineUp 2s ease-in-out;
  }
  
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  /* Animations */