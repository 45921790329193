/* body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

    .footer-flex{
    background-color: brown;
    flex: 1;
} */

footer{

    display:flex;
    flex-direction: column;
    height: 100%;
}

.footer{
    width: 100%;
    background-color: #0E223B;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.footer-columns{
    width: 75%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    /* padding: 20px 0; */
}

.footer-column{
    width: 100%;
    min-width: 300px;
    flex: 1;
    padding: 20px 0;
    }

.footer-copyright{
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    border-top: 2px solid;
    border-color: #f0dcb3;
}

.footer h5, h6{
    width: 65%;
    color: white;
    margin: 0 0 0;
    text-align: left;
}

.footer h5{
    font-size: medium;
    margin: 10px 0;
}

.footer h6{
    font-size: small;
    text-decoration: underline;
    margin: 10px 0 2px;
}


footer p{
    color: white;
    margin: 2.5px 0;
    text-align: left;
    font-size: small;
    width: 65%;
}

footer a{
    color: white;
    margin: 2.5px 0;
    font-size: small;
    width: 65%;
    text-align: center;

}

.mid-column p {
    text-align: center;
}

.footer-copyright p{
    text-align: center;
}

.mid-column h5 {
    text-align: center;
}

.mid-column h6 {
    text-align: center;
    margin: 0;
}
.mid-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* padding: 0 50px; */
}


footer {
    margin-top: auto;
  }

footer img{
    width: 180px;
}


.smicons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
}
.smicon:nth-child(2){
    margin: 0 20px;
}

svg{
   width: 40px;
   height: 40px;
   fill: #f0dcb3;
   transition: 0.3s ease-in-out;
}


svg:hover{
    fill: white;

 }

 .column-block{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 .footer-column:nth-child(3){
    display: flex;
    flex-direction: column;
    align-items: flex-end;
 }




 @media(max-width:1199px){
   .mid-column{
    order:1;
    flex-direction: row;
    margin: 0 12.5%;
}
    }
 
    @media(max-width:799px){
        .footer-column:nth-child(3){
            align-items: center;
         }
        .footer-column p{
         text-align: center;
        }
        .footer-column h5{
            text-align: center;
           }
           .footer-column h6{
            text-align: center;
           }
           .mid-column{
            flex-direction: column;
            margin: 0;
           }
         }